<template>
    <body-card style="margin: 0;border: none;">
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('teaGardenBtriService.details') }}</h4>
        </template>
        <template v-slot:body>
        <div class="banner-img-wrapper">
            <h2 class="cover-title">{{ currentLocale === 'en' ?
                        data.title_en : data.title_bn }}</h2>
            <img src="@/assets/images/cover-img.jpg" alt="">
        </div>
        <!-- Breadcraumb  -->
        <div>
            <b-container>
                <ol class="breadcrumb custom-bread">
                    <li class="breadcrumb-item"><router-link to="/portal/home" target="_self" class="">{{ $t('portal.home') }}</router-link></li>
                    <li class="breadcrumb-item"><router-link to="/portal/all-services" target="_self" class="">{{ $t('portal.all_service') }}</router-link></li>
                    <li @click="$router.go(-1)" class="breadcrumb-item"><router-link to="" target="_self">{{ $t('portal.service_details') }}</router-link></li>
                    <li class="breadcrumb-item active"><span aria-current="location">{{ $t('globalTrans.details') }}</span></li>
                </ol>
            </b-container>
        </div>
        <div class="service-description">
            <b-container>
                <body-card>
                    <div v-if="loading">
                        <Loading />
                    </div>
                    <b-overlay v-else>
                        <div class="text-right">
                            <b-button @click="pdfExport()" variant="primary" class="btn btn-sm mb-2 mr-2 mt-2">
                                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                            </b-button>
                        </div>
                        <div class="p-3" :class="currentLocale === 'en' ? 'font-en' : 'font-bn'" id="printMe">
                        <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                            <div class="" style="width:50%"><b>{{ $t('teaGardenPduService.circular_name') }}</b> : {{ currentLocale === 'en' ?
                                data.title_en : data.title_bn }}</div>
                            <div class="text-right" style="width:50%"><b>{{ $t('teaGardenPduService.application_deadline') }}</b> : {{
                                data.expire_date | dateFormat }}</div>
                            </div>
                            <div class="row mt-3">
                        <div class="col-12">
                            <div v-if="$i18n.locale === 'bn'" v-html="data.top_text_bn">
                            </div>
                            <div v-else v-html="data.top_text_en">
                            </div>
                            <table class="table table-bordered table-sm mt-2">
                            <thead class="text-center">
                                <tr>
                                <th>{{ $t('globalTrans.sl_no') }}</th>
                                <th>{{ $t('national_award.category_types') }}</th>
                                <th>{{ $t('national_award.award') }} </th>
                                <th>{{ $t('globalTrans.number') }} </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(category, index) in data.categories" :key="index">
                                <td class="text-center">{{$n(index+1)}}</td>
                                <td class="text-left">{{getCategoryName(category.value)}}</td>
                                <td class="text-left" v-if="index == 0" :rowspan="data.categories.length" v-html="$i18n.locale === 'bn' ? data.award_bn :  data.award_en"></td>
                                <td class="text-left" v-if="index == 0" :rowspan="data.categories.length">{{ $i18n.locale === 'bn' ? data.number_of_award_bn : data.number_of_award_en}}</td>
                                </tr>
                            </tbody>
                            </table>
                            <div>
                            <div v-if="$i18n.locale === 'bn'" v-html="data.bottom_text_bn">
                            </div>
                            <div v-else v-html="data.bottom_text_en">
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        <b-button @click="back" class="ml-3 mb-2"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                    </b-overlay>
                </body-card>
            </b-container>
        </div>
        </template>
    </body-card>
</template>
<script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import Loading from './loading/Details.vue'
  import { btbAwardCircularDetails, getServiceDetails } from '../../api/routes'
  import Vue from 'vue'
  import VueHtmlToPaper from 'vue-html-to-paper'
  Vue.use(VueHtmlToPaper)
  export default {
      name: 'Details',
      components: {
          Loading
      },
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
              data: {
                  id: 0,
                  service_id: 0
              },
              serviceDetails: '',
              pageStyle: {
                  styles: [
                      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                      `${teaGardenServiceBaseUrl}/custom.css`
                  ]
              }
          }
      },
      created () {
          this.getAppDetail()
          this.getServiceInfo()
      },
      computed: {
          appId () {
              return this.$route.params.id
          },
          currentLocale () {
              return this.$i18n.locale
          },
          loading () {
              return this.$store.state.commonObj.loading
          }
      },
      methods: {
        EngBangNum (n) {
            if (this.$i18n.locale === 'bn') {
                return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            } else {
                return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
            }
        },
        convertToBanglaDate (dateString) {
        const timestamp = new Date(dateString).getTime()

        const dayNumber = new Date(timestamp).getDate()
        const monthNumber = new Date(timestamp).getMonth() + 1
        const yearNumber = new Date(timestamp).getFullYear()

        // Bangla translations for months
        const monthNames = [
            'জানুয়ারি', 'ফেব্রুয়ারি', 'মার্চ', 'এপ্রিল', 'মে', 'জুন',
            'জুলাই', 'আগস্ট', 'সেপ্টেম্বর', 'অক্টোবর', 'নভেম্বর', 'ডিসেম্বর'
        ]
        const monthNamesEn = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ]

        const month = this.$i18n.locale === 'bn' ? monthNames[monthNumber - 1] : monthNamesEn[monthNumber - 1]

        // Convert day number and year to Bangla
        const banglaDayNumber = this.$n(dayNumber)
        const year = this.$n(yearNumber, { useGrouping: false })

        const banglaDate = `${banglaDayNumber} ${month} ${year}`

        return banglaDate
        },
        getCategoryName (catId) {
        const cateObj = this.data.categories.find(item => item.value === catId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
        },
          print () {
              this.$htmlToPaper('printMe', this.pageStyle)
          },
          back () {
              this.$router.go(-1)
          },
          async getAppDetail () {
              this.$store.dispatch('mutateCommonProperties', { loading: true })
              const result = await RestApi.getData(teaGardenServiceBaseUrl, `${btbAwardCircularDetails}/${this.appId}`)
              if (result.success) {
                  this.data = result.data
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false })
          },
          async getServiceInfo () {
              this.$store.dispatch('mutateCommonProperties', { loading: true })
              const result = await RestApi.getData(teaGardenServiceBaseUrl, getServiceDetails)
              if (result.success) {
                  this.serviceDetails = result.data
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false })
          },
          async pdfExport () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.id })
            params.office_type_id = this.serviceDetails.office_type_id
            params.office_id = this.serviceDetails.office_id
            const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, btbAwardCircularDetails + '/' + this.appId, params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          },
          isImage (path) {
              return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
          }
      }
  }
</script>
<style>
</style>
