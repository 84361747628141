const itfTradeFair = '/itf-trade-fair/fair-calander-circular/'
// export const itfTradeFairList = itfTradeFair + 'list'
export const getCircularData = itfTradeFair + 'get-circular-data'

const itfFairCalenderInfo = '/itf-trade-fair/fair-calander-info/'
export const itfFairCalenderInfoStore = itfFairCalenderInfo + 'store'

export const getPortalData = '/bpc-portal/portal-data'
export const getProductBusinessDirectory = '/bpc-portal/product-business-directory'

const promotion = '/promotion-management-portal/online-product-advertisement/'
export const opdPromotionalPortalList = promotion + 'show-data'

const associationInfo = '/promotion-management-portal/online-product-advertisement/'
export const associationInfoList = associationInfo + 'association-info'

const salesKit = '/promotion-management-portal/sales-kit/'
export const salesKitPortalList = salesKit + 'show-data'

const countryBranding = '/promotion-management-portal/country-branding/'
export const countryBrandingPortalList = countryBranding + 'show-data'

const eventPromotion = '/promotion-management-portal/promotion-of-events/'
export const eventPromotionalPortalList = eventPromotion + 'show-data'

// Portal question and answer routes
const portalQuestionAndAnswer = '/promotion-management-portal/portal-question-and-answer/'
export const portalQuestionAndAnswerStore = portalQuestionAndAnswer + 'store'
export const portalQuestionAndAnswerList = portalQuestionAndAnswer + 'list'
// product promotion
const prPromotion = '/promotion-management-portal/public-relationship-management/'
export const publicRelationshipPortalList = prPromotion + 'show-data'
// PDU circular management
export const circularApprovedList = '/portal/pdu/get-circular'
export const circularShow = '/portal/pdu/get-circular-details'
// BTB national award circular
export const btbAwardCircularList = '/portal/btb/get-award-circular'
export const btbAwardCircularDetails = '/portal/btb/get-award-circular-details'
export const getServiceDetails = '/portal/btb/get-service-details'
export const appOtpStore = '/portal/application/otp/store'
export const appOtpVerify = '/portal/application/otp/verify'
export const submitApplication = '/portal/btb/application/store'

const recruitment = '/license-management/recruitment/'
export const recruitmentPdf = recruitment + 'pdf'
